// Fonts
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap');

// Normalize.css
@import '~normalize.css/normalize.css';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Variables
@import 'variables';
// Font Awesome
@import '~@fortawesome/fontawesome-pro/css/all.min.css';

// Animazioni HOVER.CSS
@import "~hover.css/css/hover-min.css";
